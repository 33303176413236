import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import { v4 as uuidv4 } from 'uuid'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import hero_lens from '../../../resources/images/Homepage/hero_lens.svg'

const pressReleases = () => {
    const data = useStaticQuery(graphql`
        query PressReleasesQuery {
            allContentfulPressReleases {
                edges {
                    node {
                        pdf {
                            file {
                                url
                            }
                        }
                        title
                        date
                    }
                }
            }
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "PressReleases" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    const PressReleases = data.allContentfulPressReleases.edges

    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    } as const
    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <img className="home-fixed-top" src={hero_lens} alt="Hero Lense" />
            <section className="pt-5 pb-5 mt-5 px-2">
                <div className="container-1">
                    <div className="row mt-3">
                        <div className="mx-auto pt-2 pb-2">
                            <h1>Press Releases</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="se-section py-5 px-2">
                <div className="container-1 py-4 mobile-padding-reset">
                    <div className="row">
                        <div className="col-md-12">
                            <a href="/logos/" className="btn se-btn pull-right">
                                Lenses.io logos
                            </a>
                        </div>
                        <div className="col-md-12">
                            {PressReleases.map(({ node }) => {
                                let tempDate = node.date
                                let dateToDisplay = new Date(tempDate)
                                return (
                                    <Fragment key={uuidv4()}>
                                        <h6>
                                            {dateToDisplay.toLocaleDateString('en-US', dateOptions)}{' '}
                                        </h6>
                                        <h5>
                                            <a href={node.pdf.file.url}>{node.title}</a>
                                        </h5>
                                        <br />
                                        <hr />
                                        <br />
                                    </Fragment>
                                )
                            })}
                            {/* END OF FOR ABOVE ^ */}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default pressReleases
